<template>
  <div class="sensitive-word-form-import">
    <import-template title="导入数据" :uploadUrl="importURL" :downloadUrl="downloadUrl">
      <div slot="remark" class="remark">
        <div>单条敏感词长度2~20位，支持中文/英文/数字</div>
        <div>最大支持10MB的excel文件，超过请拆分导入</div>
      </div>
    </import-template>
  </div>
</template>

<script>
import { ImportTemplate } from 'components/bussiness/index'
import { importURL } from './api'

export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      importURL,
      downloadUrl: '/static/excel/敏感词导入模板.xlsx'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  }
}
</script>

<style lang="scss" scoped>
.sensitive-word-form-import {
  .remark {
    div {
      margin-bottom: 10px;
    }
  }
}
</style>
