// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}sensitiveword/getSensitiveWordList`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}sensitiveword/export`
// 导入
const importURL = `${API_CONFIG.controlBaseURL}sensitiveword/importSensitiveWord`
// 新增
const createURL = `${API_CONFIG.controlBaseURL}sensitiveword/addSensitiveWord`
// 编辑
const updateURL = `${API_CONFIG.controlBaseURL}sensitiveword/updateSensitiveWord`
// 删除
const deleteURL = `${API_CONFIG.controlBaseURL}sensitiveword/deleteSensitiveWord`
// 批量删除
const batchDeleteURL = `${API_CONFIG.controlBaseURL}sensitiveword/deleteSensitiveWordByIds`
// 获取详情
const queryDetailURL = `${API_CONFIG.controlBaseURL}sensitiveword/getSensitiveWordDetail`
// 获取敏感词分类列表
const getTypeListURL = `${API_CONFIG.controlBaseURL}sensitiveword/getSensitiveWordTypeList`

export {
  getListURL,
  exportListURL,
  importURL,
  createURL,
  updateURL,
  deleteURL,
  batchDeleteURL,
  queryDetailURL,
  getTypeListURL
}
