var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sensitive-word-form-import" },
    [
      _c(
        "import-template",
        {
          attrs: {
            title: "导入数据",
            uploadUrl: _vm.importURL,
            downloadUrl: _vm.downloadUrl,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "remark",
              attrs: { slot: "remark" },
              slot: "remark",
            },
            [
              _c("div", [_vm._v("单条敏感词长度2~20位，支持中文/英文/数字")]),
              _c("div", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }